<template>
    <div>
        <div
        class="text-green-700 cursor-pointer mt-4"
        @click.stop="is_details_pc_open = !is_details_pc_open"
    >
        <span v-if="is_details_pc_open">-</span> <span v-else>+</span> En savoir plus
    </div>
    <div
        class="flex flex-col"
        v-for="(detail_pc, index) in details_pc"
        :key="index + 'details'"
        v-if="is_details_pc_open"
    >
        <div class="flex flex-col mt-4" v-if="item[detail_pc.key]">
            <p class="text-promy-grey-70 text-sm leading-tight">
                {{ detail_pc.label }}
            </p>
            <p class="text-promy-grey-100 font-medium text-lg">
                {{ item[detail_pc.key] }}
            </p>
        </div>
    </div>
    </div>
 
</template>

<script>
export default {
  props: {
    item: {
        type: Object,
      required: true,
    },
  },
  data() {
    return {
        is_details_pc_open : false,
        details_pc : [
    {
        key: 'date_reelle_autorisation',
        label: "Date réelle d'autorisation",
    },
    {
        key: 'date_reelle_doc',
        label: "Date réelle d'ouverture de chantier",
    },
    {
        key: 'date_reelle_daact',
        label: "Date réelle d'achèvement des travaux",
    },
    {
        key: 'cat_dem',
        label: "Catégorie du demandeur (maître d'ouvrage) selon Sitadel",
    },
    {
        key: 'ape_dem',
        label: "Code d'activité principale de l'établissement d'un demandeur avéré en tant que personne morale",
    },
    {
        key: 'nature_projet_declaree',
        label: 'Nature du projet déclarée par le demandeur',
    },
    {
        key: 'nature_projet_completee',
        label: 'Nature de projet détaillée',
    },
    {
        key: 'destination_principale',
        label: 'Destination principale',
    },
    {
        key: 'type_princip_logts_crees',
        label: 'Type principal des logements créés',
    },
    {
        key: 'type_transfo_principal',
        label: 'Type principal de transformation',
    },
    {
        key: 'type_princip_locaux_transformes',
        label: "Type principal des locaux d'origine transformés",
    },
    {
        key: 'utilisation',
        label: 'Utilisation visée des locaux à construire',
    },
    {
        key: 'res_princip_ou_second',
        label: "Type d'habitation principale ou secondaire",
    },
    {
        key: 'type_annexe',
        label: "Type d'annexe",
    },
    {
        key: 'residence',
        label: 'Type de résidence',
    },
]
    }
  },

}
</script>

