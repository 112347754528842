<template>
  <accordion :indice="active_accordion">
    <accordion-item
      :id="`header_pc_${index}`"
      class="shadow-box mb-4 lg:mb-0"
      v-for="(item, index) in list"
      :key="index"
      :custom_index="`pc-${index}`"
      :should_active="true"
    >
      <template slot="accordion-trigger">
        <a
          :id="`pc_${index}`"
          @click="activingAccordionPC(index, item)"
          class="
            w-full
            flex
            items-center
            py-2
            px-4
            bg-white
            rounded-lg
            lg:border-t lg:border-promy-gray-150 lg:py-4 lg:ml-4
          "
          :class="{ 'rounded-b-none': active_accordion === `pc-${index}` }"
        >
          <ProIcon name="marker" color="#EB421D" />
          <p class="font-main font-bold lg:text-sm text-promy-orange-600 px-4">
            {{ item[0].adresse }}
          </p>
          <MinusIcon
            v-if="active_accordion === `pc-${index}`"
            class="ml-auto w-3 h-3"
           color="text-promy-blue-120"
          />
          <PlusIcon v-else class="ml-auto w-3 h-3" color="text-promy-blue-120" />
        </a>
      </template>
      <template slot="accordion-content">
        <div
          v-for="(sub_item, idx) in item"
          :key="idx"
          class="
            bg-white
            lg:bg-promy-gray-50
            p-6
            lg:px-10
            border-t border-promy-gray-100
          "
        >
          <div class="flex flex-col">
            <div class="flex gap-2 items-center">
              <div class="w-6 mb-2">
                <img
                  :src="`/images/GU/etudeCommerciale/${
                    typesBienIcon[sub_item.type_evt ? sub_item.type_evt : 'N.C']
                  }.svg`"
                  class="w-full"
                />
              </div>
              <div class="flex flex-col">
                <span
                  class="
                    capitalize
                    text-lg
                    leading-none
                    font-medium
                    text-promy-gray-625
                  "
                  :title="sub_item.type_evt"
                >
                  {{ sub_item.type_evt | isExist }}
                </span>
                <span class="text-promy-green-350 font-bold">
                  {{ sub_item.date_reelle_autorisation | isExist }}
                </span>
              </div>
            </div>
            <div class="flex items-end justify-between mt-6">
              <div class="grid grid-cols-2 gap-4">
                <div class="flex gap-2 items-center">
                  <div class="w-7">
                    <img
                      src="/images/GU/etudeCommerciale/pieces.svg"
                      class="w-full"
                    />
                  </div>
                  <div class="flex flex-col">
                    <span
                      class="
                        capitalize
                        leading-none
                        text-promy-gray-625 text-promy-smaller
                      "
                    >
                      Nbr de logement
                    </span>
                    <span class="text-promy-gray-600 font-medium text-sm">
                      {{ sub_item.nombre_logements | isExist }}
                    </span>
                  </div>
                </div>
                <div class="flex gap-2 items-center">
                  <div class="w-7">
                    <img
                      src="/images/GU/etudeCommerciale/size-icon.svg"
                      class="w-full"
                    />
                  </div>
                  <div class="flex flex-col">
                    <span
                      class="
                        capitalize
                        leading-none
                        text-promy-gray-625 text-promy-smaller
                      "
                    >
                      Surface du plancher
                    </span>
                    <span class="text-promy-gray-600 font-medium text-sm">
                      {{ sub_item.sdp | isExist('m²') }}
                    </span>
                  </div>
                </div>
                <div class="flex gap-2 items-center">
                  <div class="w-7">
                    <img
                      src="/images/GU/etudeCommerciale/size-2-icon.svg"
                      class="w-full"
                    />
                  </div>
                  <div class="flex flex-col">
                    <span
                      class="
                        capitalize
                        leading-none
                        text-promy-gray-625 text-promy-smaller
                      "
                    >
                      Sol
                    </span>
                    <span class="text-promy-gray-600 font-medium text-sm">
                      {{ sub_item.superficie_t | isExist('m²') }}
                    </span>
                  </div>
                </div>
              </div>
             
            </div>
            <DetailsPermisConstruire :item="sub_item"></DetailsPermisConstruire>
          </div>
        </div>
      </template>
    </accordion-item>
  </accordion>
</template>

<script>
import etudeCommerciale from '@/mixins/etudeCommerciale'
import DetailsPermisConstruire from './DetailsPermisConstruire'
export default {
  components:{
    DetailsPermisConstruire
  },
  mixins: [etudeCommerciale],
  props: {
    list: {
      type: Array | null,
      required: true,
    },
  },
  data() {
    return {
      typesBienIcon: {
        'Commencé': 'travaux-icon',
        'Autorisé': 'autorisation-icon',
        'Terminé': 'travaux-icon',
        'N.C': 'default-building-icon',
      },
    }
  },
  filters: {
    checkInfo(value) {
      return value ? value : 'N.C'
    },
  },

  methods: {
    activingAccordionPC(idx, item) {
      this.activingAccordion(
        idx,
        [item[0].location.coordinates[1], item[0].location.coordinates[0]],
        'pc',
      )
    },
  },
}
</script>

<style></style>
