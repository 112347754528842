<template>
  <accordion :indice="active_accordion">
    <accordion-item
      :id="`header_dvf_${index}`"
      class="shadow-box mb-4 lg:mb-0"
      v-for="(item, index) in list"
      :key="index"
      :custom_index="`dvf-${index}`"
      :should_active="true"
    >
      <template slot="accordion-trigger">
        <a
          :id="`dvf_${index}`"
          @click="activingAccordionDVF(index, item)"
          class="
            w-full
            flex
            items-center
            py-2
            px-4
            bg-white
            rounded-lg
            lg:border-t lg:border-promy-gray-150 lg:py-4 lg:ml-4
          "
          :class="{ 'rounded-b-none': active_accordion === `dvf-${index}` }"
        >
          <ProIcon name="marker" color="#1D63EB" />
          <p class="font-main font-bold lg:text-sm text-promy-blue-250 px-4">
            {{ getAddressDvf(item[0]) | truncate(46, '...') }}
          </p>
          <MinusIcon
            v-if="active_accordion === `dvf-${index}`"
            class="ml-auto w-3 h-3"
            color="text-promy-blue-120"
          />
          <PlusIcon v-else class="ml-auto w-3 h-3" color="text-promy-blue-120" />
        </a>
      </template>
      <template slot="accordion-content">
        <div
          v-for="(sub_item, idx) in item"
          :key="idx"
          class="
            bg-white
            lg:bg-promy-gray-50
            p-6
            lg:px-10
            flex flex-col
            gap-4
            border-t border-promy-gray-100
          "
        >
          <div class="flex flex-col">
            <div class="flex gap-2 items-center">
              <div class="w-6 mb-2">
                <img
                  :src="`/images/GU/etudeCommerciale/${
                    typesBienIcon[
                      sub_item.type_local ? sub_item.type_local : 'N.C'
                    ]
                  }.svg`"
                  class="w-full"
                />
              </div>
              <div class="flex flex-col">
                <span
                  class="
                    capitalize
                    text-base
                    leading-none
                    font-medium
                    text-promy-gray-625
                  "
                  :title="item.type_local"
                >
                  {{ sub_item.type_local | isExist }}
                </span>
                <span class="text-promy-gray-600 font-medium">
                  Vendu le
                  <span class="text-promy-green-350 font-bold">{{
                    sub_item.date_mutation | isExist
                  }}</span>
                </span>
              </div>
            </div>
            <div class="flex items-end justify-between mt-6">
              <div class="grid grid-cols-2 gap-4">
                <div class="flex gap-2 items-center">
                  <div class="w-7">
                    <img
                      src="/images/GU/etudeCommerciale/pieces.svg"
                      class="w-full"
                    />
                  </div>
                  <div class="flex flex-col">
                    <span
                      class="
                        capitalize
                        leading-none
                        text-promy-gray-625 text-promy-smaller
                      "
                    >
                      Pieces
                    </span>
                    <span class="text-promy-gray-600 font-medium text-sm">
                      {{ sub_item.nombre_lots | isExist }}
                    </span>
                  </div>
                </div>
                <div class="flex gap-2 items-center">
                  <div class="w-7">
                    <img
                      src="/images/GU/etudeCommerciale/size-icon.svg"
                      class="w-full"
                    />
                  </div>
                  <div class="flex flex-col">
                    <span
                      class="
                        capitalize
                        leading-none
                        text-promy-gray-625 text-promy-smaller
                      "
                    >
                      Surface Terrain
                    </span>
                    <span class="text-promy-gray-600 font-medium text-sm">
                      {{ sub_item.surface_terrain | isExist('m²') }}
                    </span>
                  </div>
                </div>
                <div class="flex gap-2 items-center">
                  <div class="w-7">
                    <img
                      src="/images/GU/etudeCommerciale/size-2-icon.svg"
                      class="w-full"
                    />
                  </div>
                  <div class="flex flex-col">
                    <span
                      class="
                        capitalize
                        leading-none
                        text-promy-gray-625 text-promy-smaller
                      "
                    >
                      Surface
                    </span>
                    <span class="text-promy-gray-600 font-medium text-sm">
                      {{ sub_item.surface_reelle_bati | isExist('m²') }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex flex-col space-y-1 justify-end items-start">
                <span class="text-promy-green-350 text-xl leading-4 font-bold">
                  {{
                    round(sub_item.valeur_fonciere)
                      | roundNumber
                      | formatEUR(0)
                      | isExist
                  }}
                </span>
                <span class="text-promy-gray-600 font-normal text-sm">
                  {{
                    types_de_bien_pour_choix_terrain.includes(
                      sub_item.type_local,
                    ) && sub_item.surface_terrain
                      ? `${formatEuro(
                          round(sub_item.valeur_fonciere) /
                            parseInt(sub_item.surface_terrain),
                        )} /m²`
                      : ''
                  }}
                  {{
                    types_de_bien_pour_choix_maison.includes(
                      sub_item.type_local,
                    ) && sub_item.surface_reelle_bati
                      ? `${formatEuro(
                          round(sub_item.valeur_fonciere) /
                            parseInt(sub_item.surface_reelle_bati),
                        )} /m²`
                      : ''
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </accordion-item>
  </accordion>
</template>

<script>
import etudeCommerciale from '@/mixins/etudeCommerciale'
import helpers from '@/mixins/helpers'
export default {
  mixins: [etudeCommerciale, helpers],
  props: {
    list: {
      type: Array | null,
      required: true,
    },
  },

  data() {
    return {
      types_de_bien_pour_choix_terrain: [],
      types_de_bien_pour_choix_maison: [
        'Maison',
        'Appartement',
        'Dépendance',
        'Local industriel. commercial ou assimilé',
      ],
      typesBienIcon: {
        Maison: 'maison-icon',
        Appartement: 'appartement-icon',
        Dépendance: 'default-building-icon',
        'Local industriel. commercial ou assimilé': 'industriel-icon',
        'N.C': 'default-building-icon',
      },
    }
  },

  filters: {
    checkInfo(value) {
      return value ? value : 'N.C'
    },
    checkSurfaceInfo(value) {
      return value ? `${value} m²` : 'N.C'
    },
  },

  methods: {
    getAddressDvf(item) {
      return item.hasOwnProperty('adresse')
        ? item.adresse
        : [item.adresse_nom_voie, item.nom_commune, item.code_postal].join(' ')
    },
    activingAccordionDVF(idx, item) {
      this.activingAccordion(
        idx,
        [item[0].location.coordinates[0], item[0].location.coordinates[1]],
        'dvf',
      )
    },
    formatEuro(value, fractionDigits) {
      return this.$options.filters.formatEUR(value, fractionDigits)
        ? this.$options.filters.formatEUR(value, fractionDigits)
        : ''
    },
  },
}
</script>

<style></style>
