<template>
  <div
    class="
      w-[36rem]
      lg:w-full
      bg-promy-gray-50
      lg:bg-white lg:border-t lg:border-promy-gray-150
      px-12
      sm:px-6
      pt-36
      lg:pt-10
      sm:pt-6
      space-y-6
      left-side
      md:overflow-y-auto md:max-h-[28rem]
      sm:max-h-[19rem]
    "
  >
    <p class="font-main font-bold text-xl lg:text-base text-promy-purply-900">
      Les derniers permis et transactions à proximité
    </p>
    <ProAutoComplete
      class="border border-promy-gray-150 rounded-lg hidden"
      placeholder="Rechercher"
      :list="[]"
    />
    <div>
      <div class="flex space-x-4 sm:flex-col sm:space-x-0 sm:space-y-2">
        <TabsButton
          v-for="(tab, index) in tabs"
          :key="index"
          :text="tab.text"
          :active="current_tab === tab.slug"
          @click="current_tab = tab.slug"
        />
      </div>
      <p class="font-main font-semibold text-promy-purply-900 my-4">
        {{ isVentesProximite ? 'Les plus récentes' : 'Les plus récents' }}
      </p>
      <div
        id="details_etude_commerciale"
        class="
          lg:-mx-12
          sm:-mx-6
          overflow-auto
          h-[43vh]
          -mr-4
          pr-4
          md:overflow-hidden md:h-auto
          scrollbar-thin
          scrollbar-thumb-promy-gray-180
          scrollbar-track-promy-gray-100
        "
      >
        <template v-if="current_tab !== 'permis_de_construire'">
          <VentesProximite
            :list="ventes_proximite"
            v-if="ventes_proximite && ventes_proximite.length"
          />
          <span class="font-bold text-promy-primary" v-else-if="current_tab">
            Ces informations ne sont pas disponibles pour cette adresse
          </span>
        </template>

        <template v-if="current_tab !== 'ventre_a_proximite'">
          <PermisConstruire
            :list="permis_construire"
            v-if="permis_construire && permis_construire.length"
          />
          <span class="font-bold text-promy-primary" v-else-if="current_tab">
            Ces informations ne sont pas disponibles pour cette adresse
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import VentesProximite from './VentesProximite.vue'
import PermisConstruire from './PermisConstruire.vue'

export default {
  components: {
    VentesProximite,
    PermisConstruire,
  },

  props: {
    ventes_proximite: {
      type: Array | null,
      required: true,
    },
    permis_construire: {
      type: Array | null,
      required: true,
    },
  },
  data() {
    return {
      current_tab: null,
      tabs: [
        {
          text: 'Ventes à proximité',
          slug: 'ventre_a_proximite',
        },
        {
          text: 'Permis de construire',
          slug: 'permis_de_construire',
        },
      ],
    }
  },
  computed: {
    isVentesProximite() {
      return this.current_tab === 'ventre_a_proximite'
    },
  },
  created() {
    this.sortDataByDate()
  },
  methods: {
    sortDataByDate() {
      if (this.ventes_proximite && this.ventes_proximite.length) {
        this.ventes_proximite.forEach((group_dvf) => {
          return group_dvf.sort(
            (a, b) => new Date(b.date_mutation) - new Date(a.date_mutation),
          )
        })
      }
      if (this.permis_construire && this.permis_construire.length) {
        this.permis_construire.forEach((group_permis_construction) => {
          group_permis_construction.sort(
            (a, b) => new Date(b.date_mutation) - new Date(a.date_mutation),
          )
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.left-side {
  height: calc(100vh - 5rem);
  @screen lg {
    height: calc(100vh - 20rem);
  }
}
</style>
